import { createTheme, responsiveFontSizes, adaptV4Theme } from "@mui/material";
import { PBVisualType } from "../../models/PBVisualType";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
export const MenuProps: any = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
  getContentAnchorEl: null,
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "center",
  },
  transformOrigin: {
    vertical: "top",
    horizontal: "center",
  },
  variant: "menu",
};

export const getVisualNameByType = (visualType: PBVisualType) => {
  switch (visualType) {
    case PBVisualType.Report:
      return "report";
    case PBVisualType.Dashboard:
      return "dashboard";
    case PBVisualType.Visual:
      return "visual";
    case PBVisualType.URL:
      return "link";
    default:
      return "report";
  }
};

// Create a theme instance.
const themeInstance = createTheme(
  adaptV4Theme({
    breakpoints: {
      values: {
        xs: 360,
        sm: 640,
        md: 768,
        lg: 1368,
        xl: 1920,
      },
    },
  })
);
export const theme = responsiveFontSizes(themeInstance);

export const gridColumnSize = () => {
  if (window.innerWidth < theme.breakpoints.values["md"]) {
    return 1;
  } else if (window.innerWidth < theme.breakpoints.values["lg"]) {
    return 3;
  } else if (window.innerWidth > theme.breakpoints.values["lg"]) {
    return 6;
  } else {
    return 6;
  }
};
