import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import * as msTeams from "@microsoft/teams-js";
import {
  adaptV4Theme,
  createTheme,
  CssBaseline,
  StyledEngineProvider,
  Theme,
  ThemeProvider,
} from "@mui/material";
import { Fragment, Suspense, useMemo } from "react";
import { connect } from "react-redux";
import { BrowserRouter, Switch } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { AppInsightsContextProvider } from "./appinsights/appinsightscontext";
import { Spinner } from "./components/ui-helpers/spinner";
import { AppRoutes } from "./routes/routes";

declare module "@mui/styles/defaultTheme" {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

interface IApp {
  pca: PublicClientApplication;
  palette?: any;
}
const App = ({ pca, palette }: IApp) => {
  msTeams.initialize();
  const theme = useMemo(
    () =>
      createTheme(
        adaptV4Theme({
          palette: { ...palette },
          typography: {
            h2: {
              fontSize: "1.5rem",
              fontStyle: "normal",
              lineHeight: "48px",
            },
            h4: {
              fontSize: "1.25rem",
              fontStyle: "normal",
              lineHeight: "28px",
            },
            subtitle1: {
              fontSize: "1.125rem",
              fontStyle: "normal",
              lineHeight: "24px",
            },
            subtitle2: {
              fontSize: "1rem",
              fontStyle: "normal",
              lineHeight: "24px",
            },
            allVariants: {
              color: "#4D4D4D",
            },
          },
        })
      ),
    [palette]
  );

  return (
    <BrowserRouter>
      <AppInsightsContextProvider>
        <MsalProvider instance={pca}>
          <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
              <CssBaseline />
              <Switch>
                <Suspense fallback={<Spinner />}>
                  <Fragment>
                    <main>
                      <AppRoutes />
                      <ToastContainer />
                    </main>
                  </Fragment>
                </Suspense>
              </Switch>
            </ThemeProvider>
          </StyledEngineProvider>
        </MsalProvider>
      </AppInsightsContextProvider>
    </BrowserRouter>
  );
};

const mapStateToProps = ({ themeReducer: { palette } }: any) => ({
  palette,
});
export default connect(mapStateToProps, null)(App);
