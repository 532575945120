const types = {
  CHANGE_THEME_PALETTE: "CHANGE_THEME_PALETTE",
};

export const changeThemePaletteRequest = `${types.CHANGE_THEME_PALETTE}_REQUEST`;
export const changeThemePaletteSuccess = `${types.CHANGE_THEME_PALETTE}_SUCCESS`;
export const changeThemePaletteFailure = `${types.CHANGE_THEME_PALETTE}_FAILURE`;

export const dispatchchangeThemePaletteSuccess = (data: any) => ({
  type: changeThemePaletteSuccess,
  palette: data,
});
