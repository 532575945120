import { IPrivacyAction, privacyState } from "../../models/privacy";
import {
  fetchEntitiesSuccess,
  fetchPrivacyFailure,
  fetchPrivacyRequest,
  fetchSendGridAPIKeySuccess,
  postEarseDataRequest,
  postEarseDataSuccess,
  searchPIIDataFailure,
  searchPIIDataRequest,
  searchPIIDataSuccess,
  updatePIIDataSuccess,
} from "../actions/privacy";

export const getInitialState = () => ({
  isFetching: false,
  entities: [],
  piiItems: [],
  sendGridAPIKey: "",
});

const privacyReducer = (
  state: privacyState = getInitialState(),
  action: IPrivacyAction
) => {
  switch (action.type) {
    case fetchPrivacyRequest:
    case postEarseDataRequest:
      return { ...state, isFetching: true };
    case fetchEntitiesSuccess:
      return {
        ...state,
        isFetching: false,
        entities: action.entities,
      };
    case fetchPrivacyFailure:
      return { ...state, isFetching: false };
    case searchPIIDataRequest:
      return { ...state, isSearching: true };
    case searchPIIDataSuccess:
      return {
        ...state,
        isSearching: false,
        piiItems: action.data,
      };
    case updatePIIDataSuccess:
    case postEarseDataSuccess:
      return {
        ...state,
        isSearching: false,
      };
    case searchPIIDataFailure:
      return { ...state, isSearching: false };
    case fetchSendGridAPIKeySuccess:
      return { ...state, sendGridAPIKey: action.data };
    default:
      return state;
  }
};

export default privacyReducer;
