import { gridColumnSize } from "../../features/audiences/utils";
import { audienceState, IAudienceAction } from "../../models/audience";
import { isB2B } from "../../utils/check-teams";
import {
  audiencesDragEndSuccess,
  checkAudienceNameRequest,
  checkAudienceNameSuccess,
  closeDrawer,
  createAudiencesFailure,
  createAudiencesRequest,
  createAudiencesSuccess,
  deleteAudienceRequest,
  deleteAudienceUserRequest,
  Failure,
  fetchAudienceByAudienceIdSuccess,
  fetchAudiencesFailure,
  fetchAudiencesRequest,
  fetchAudiencesSuccess,
  fetchAudienceUsersRequest,
  fetchAudienceUsersSuccess,
  fetchB2CUsersRequest,
  fetchB2CUsersSuccess,
  fetchThumbnailsSuccess,
  importAudienceRequest,
  importAudienceSuccess,
  openDrawer,
  resetADUsersSuccess,
  resetAudienceCheck,
  searchB2CUsersSuccess,
  updateADUsers,
  updateAudienceRequest,
  updateAudienceSuccess,
  updateUserCanedit,
} from "../actions/audiences";
import { fetchB2CAdminUsersSuccess, signInFailure } from "../actions/auth";
import { createReportRequest, deleteReportRequest } from "../actions/report";

export const getInitialState = () => ({
  isFetching: false,
  audience: {},
  audiences: [],
  users: [],
  tempUsers: [],
  skipToken: "",
  openDrawer: false,
  audiencesUsers: [],
  isClearSelection: false,
  thumbnails: [],
  isWorkspacExists: false,
  isAudienceExists: false,
  createAudienceError: null,
  canSaveDisabled: isB2B ? true : false,
  updateAudienceUsers: [],
});

const audiencesReducer = (
  state: audienceState = getInitialState(),
  action: IAudienceAction
) => {
  switch (action.type) {
    case fetchAudiencesRequest:
    case fetchB2CUsersRequest:
    case createAudiencesRequest:
    case deleteReportRequest:
    case createReportRequest:
    case deleteAudienceUserRequest:
    case updateAudienceRequest:
    case deleteAudienceRequest:
    case checkAudienceNameRequest:
    case importAudienceRequest:
      return { ...state, isFetching: true };

    case fetchAudienceUsersRequest:
      return { ...state, isFetchingUsers: true };
    case fetchAudiencesSuccess:
      const thumbnails: any = state.thumbnails;
      if (thumbnails.length > 0) {
        (action.audiences || []).forEach((item: any) => {
          const findaud = thumbnails.find(
            (thumb: any) => thumb.audienceId === item.id
          );
          if (findaud && findaud.imageArray) {
            item.url = `data:image/png;base64,${findaud.imageArray}`;
          }
        });
      }
      action.audiences?.sort((a, b) => (a.sequence || 0) - (b.sequence || 0));
      return {
        ...state,
        isFetching: false,
        audiences: action.audiences,
      };
    case fetchAudienceByAudienceIdSuccess:
      return {
        ...state,
        isFetching: false,
        audience: action.audience,
      };
    case fetchB2CAdminUsersSuccess:
      return { ...state, isFetching: false };
    case fetchB2CUsersSuccess:
      return {
        ...state,
        isFetching: false,
        users: action.usersResp && action.usersResp,
      };
    case searchB2CUsersSuccess:
      return {
        ...state,
        isFetching: false,
        tempUsers: action.usersResp && action.usersResp,
      };
    case fetchAudienceUsersSuccess:
      return {
        ...state,
        isFetchingUsers: false,
        canSaveDisabled: false,
        audiencesUsers: action.usersResp && action.usersResp,
      };
    case fetchThumbnailsSuccess:
      const audiences = [...state.audiences];
      audiences.forEach((item: any) => {
        const findaud = action.thumbnails.find(
          (thumb: any) => thumb.audienceId === item.id
        );
        if (findaud && findaud.imageArray) {
          item.url = `data:image/png;base64,${findaud.imageArray}`;
        }
      });
      return {
        ...state,
        isFetching: false,
        audiences,
        thumbnails: action.thumbnails,
      };
    case createAudiencesSuccess:
      return { ...state, isFetching: false, createAudienceError: null };
    case updateAudienceSuccess:
      return { ...state, isClearSelection: true };
    case openDrawer:
      return { ...state, openDrawer: true };
    case closeDrawer:
      return { ...state, openDrawer: false };
    case fetchAudiencesFailure:
      return {
        ...state,
        isFetching: false,
        isFetchingUsers: false,
        canSaveDisabled: isB2B ? true : false,
      };
    case audiencesDragEndSuccess:
      const { source, destination, type } = action.dragEndResult;
      let newState = [...state.audiences];
      if (type !== "droppableSubItem") {
        const items = reorder(state.audiences, source.index, destination.index);
        newState = [...items];
      } else {
        const audienceIndex = source.droppableId.split("_")[0];
        const items = reorder(
          state.audiences[audienceIndex].reports,
          source.index,
          (
            gridColumnSize() === 1
              ? destination.index > gridColumnSize()
              : destination.index >= gridColumnSize()
          )
            ? destination.index - 1
            : destination.index
        );
        newState[audienceIndex].reports = [...items];
      }
      return {
        ...state,
        audiences: [...newState],
      };
    case updateUserCanedit:
      const { audiencesUsers } = state;
      const updatedAudiencesUsers = audiencesUsers.map((item: any) => {
        if (item.id === action.audienceId) {
          item.canEdit = action.canedit;
        }
        return item;
      });
      return {
        ...state,
        audiencesUsers: updatedAudiencesUsers,
      };
    case checkAudienceNameSuccess:
      return {
        ...state,
        isFetching: false,
        isWorkspacExists: action.existisChek.isExistInPB,
        isAudienceExists: action.existisChek.isExist,
        createAudienceError: null,
      };
    case importAudienceSuccess:
      return {
        ...state,
        isFetching: false,
      };
    case resetAudienceCheck:
      return {
        ...state,
        isWorkspacExists: false,
        isAudienceExists: false,
      };
    case Failure:
      return {
        ...state,
        isFetching: false,
      };
    case createAudiencesFailure:
      return {
        ...state,
        isFetching: false,
        createAudienceError: action.createAudienceError,
      };
    case resetADUsersSuccess:
      return {
        ...state,
        users: [],
      };
    case signInFailure:
      return { ...state, isFetching: false };
    case updateADUsers:
      return { ...state, audiencesUsers: [...action.updateAudienceUsers] };
    default:
      return state;
  }
};

export default audiencesReducer;

const reorder = (list: any, startIndex: number, endIndex: number) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result as any[];
};
