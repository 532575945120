//@ts-nocheck
import {
  ReactPlugin,
  withAITracking,
} from "@microsoft/applicationinsights-react-js";
import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { globalHistory } from "@reach/router";

const reactPlugin = new ReactPlugin();

const ai = new ApplicationInsights({
  config: {
    instrumentationKey: process.env.REACT_APP_INSTRUMENTATIONKEY,
    extensions: [reactPlugin],
    extensionConfig: {
      [reactPlugin.identifier]: { history: globalHistory },
    },
  },
});
ai.loadAppInsights();

const withTracking = (Component) => withAITracking(reactPlugin, Component);
export default withTracking;
export const appInsights = ai.appInsights;
export { reactPlugin };
