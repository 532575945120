export const OPEN_MODAL = "OPEN_MODAL";
export const CLOSE_CURRENT_MODAL = "CLOSE_CURRENT_MODAL";
export const defaultState = { name: "", payload: {} };

export interface modalState {
  name: string;
  payload: object;
}
export const getInitialState = () => ({
  name: "",
  payload: {},
  config: {},
});
export const openModalSuccess = (payload: any) => ({
  type: OPEN_MODAL,
  payload,
});
export const closeModalSuccess = () => ({
  type: CLOSE_CURRENT_MODAL,
});
const modalReducer = (state: modalState = getInitialState(), action: any) => {
  switch (action.type) {
    case OPEN_MODAL:
      return {
        ...state,
        name: action.payload.name,
        payload: action.payload?.payload,
        config: action.payload?.config,
      };
    case CLOSE_CURRENT_MODAL:
      return getInitialState();
    default:
      return state;
  }
};

export const closeCurrentModal = () => (dispatch: any) =>
  dispatch(closeModalSuccess());
export const openModal = (payload: any) => (dispatch: any) =>
  dispatch(openModalSuccess(payload));

export default modalReducer;
