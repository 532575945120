import axios from "axios";
import * as R from "ramda";
import { acquireAccessToken, acquireTokenForPB } from "../..";
import { handleDataPortalHttpResponse } from "../../redux/utils";

export const apiServer =
  process.env.REACT_APP_API_URL || "http://localhost:3000";
export const datawareUrl = process.env.REACT_APP_DataPlatform_API_URL;
export const dataPrivacyUrl = process.env.REACT_APP_PrivacyPlatform_API_URL;
export const apiBaseUrl = `${apiServer}`;

export const dataPortalApi = axios.create({
  baseURL: apiBaseUrl,
});
export const datawareApi = axios.create({
  baseURL: datawareUrl,
});
export const dataPrivacyApi = axios.create({
  baseURL: dataPrivacyUrl,
});

export const basicAuthRequest = async (config: any) => {
  const token = await acquireAccessToken();
  const withAuthTokenConfig = R.mergeDeepLeft(config, {
    headers: { Authorization: `Bearer ${token}` },
  });
  return withAuthTokenConfig;
};
/**
 * authRequest :: AxiosConfig -> Promise Error AxiosResponse
 * AxiosConfig = Object
 * AxiosResponse = Object
 */
export const authRequest = async (config: any) => {
  try {
    const withAuthTokenConfig = await basicAuthRequest(config);
    const data = await dataPortalApi(withAuthTokenConfig);
    handleDataPortalHttpResponse();
    return data;
  } catch (error) {
    handleDataPortalHttpResponse(error);
    return await Promise.reject(error);
  }
};

/**
 * authRequest :: AxiosConfig -> Promise Error AxiosResponse
 * AxiosConfig = Object
 * AxiosResponse = Object
 */
export const authDatawareRequest = async (config: any) => {
  try {
    const withAuthTokenConfig = await basicAuthRequest(config);
    const data = await datawareApi(withAuthTokenConfig);
    handleDataPortalHttpResponse();
    return data;
  } catch (error) {
    handleDataPortalHttpResponse(error);
    return await Promise.reject(error);
  }
};

/**
 * authRequest :: AxiosConfig -> Promise Error AxiosResponse
 * AxiosConfig = Object
 * AxiosResponse = Object
 */
export const authDataPrivacyRequest = async (config: any) => {
  try {
    const withAuthTokenConfig = await basicAuthRequest(config);
    const data = await dataPrivacyApi(withAuthTokenConfig);
    handleDataPortalHttpResponse();
    return data;
  } catch (error) {
    handleDataPortalHttpResponse(error);
    return await Promise.reject(error);
  }
};

/**
 * authRequest :: AxiosConfig -> Promise Error AxiosResponse
 * AxiosConfig = Object
 * AxiosResponse = Object
 */
export const authPBRequest = async (config: any, url: string) => {
  const token = await acquireTokenForPB();
  localStorage.setItem("accessToken", token);
  const withAuthTokenConfig = R.mergeDeepLeft(config, {
    headers: { Authorization: `Bearer ${token}` },
  });
  try {
    const dataPortalPB = axios.create({ url });
    const data = await dataPortalPB(url, withAuthTokenConfig);
    handleDataPortalHttpResponse();
    return data;
  } catch (error) {
    //handleDataPortalHttpResponse(error);
    return await Promise.reject(error);
  }
};

export const PBRequest = async (config: any, url: string) => {

  try {
    const dataPortalPB = axios.create({ url });
    const data = await dataPortalPB(url);
    handleDataPortalHttpResponse();
    return data;
  } catch (error) {
    //handleDataPortalHttpResponse(error);
    return await Promise.reject(error);
  }
};

/**
 * isProd :: () -> Boolean
 */
export const isProd = () => process.env.NODE_ENV === "production";

/**
 * isUnauthorizedError :: Error -> Boolean
 */
export const isUnauthorizedError = R.pathEq(["response", "status"], 401);
