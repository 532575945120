import { fetchAudiencesFailure } from "../actions/audiences";
import {
  fetchPBWorkspaceReportRequest,
  fetchPBWorkspaceReportSuccess,
  lastViewedReportFailure,
  lastViewedReportRequest,
  lastViewedReportSuccess,
  reportFailure,
  resetReportDetails,
  selectedReportDetails,
  viewAadReportFailure,
  viewAadReportSuccess,
  viewReportRequest,
  viewReportSuccess
} from "../actions/report";

export const getInitialState = () => ({
  isFetching: false,
  lastViewedReports: [],
  workspaces: [],
  reportDetails: null,
  aadReportDetails: null,
  selectedReport: null,
  reportError: null
});

const reportReducer = (state: any = getInitialState(), action: any) => {
  switch (action.type) {
    case fetchPBWorkspaceReportRequest:
    case viewReportRequest:
    case lastViewedReportRequest:
      return { ...state, isFetching: true };
    case fetchPBWorkspaceReportSuccess:
      return {
        ...state,
        isFetching: false,
        workspaces: action.workspaces,
      };
    case viewReportSuccess:
      return {
        ...state,
        reportDetails: action.reportdetails,
        isFetching: false,
      };
    case resetReportDetails:
      return {
        ...state,
        reportDetails: null,
        isFetching: false,
      };
    case selectedReportDetails:
      return {
        ...state,
        selectedReport: action.selectedReport,
      };
    case lastViewedReportSuccess:
      return {
        ...state,
        isFetching: false,
        lastViewedReports: action.reports,
      };
    case fetchAudiencesFailure:
    case lastViewedReportFailure:
    case reportFailure:
      return { ...state, isFetching: false };
    case viewAadReportSuccess:
      return {
        ...state,
        aadReportDetails: action.reportdetails,
        reportError: null,
        isFetching: false,
      };
    case viewAadReportFailure:
      return {
        ...state,
        reportError: action.reportError,
        isFetching: false,
      }
    default:
      return state;
  }
};

export default reportReducer;
