import { Backdrop, Theme } from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import { BeatLoader } from "react-spinners";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    backdrop: {
      zIndex: 2000,
      color: "#fff",
      position: "absolute",
    },
  })
);
export const Spinner = ({ ...props }) => {
  const classes = useStyles();
  return (
    <Backdrop className={classes.backdrop} open={true} {...props}>
      {/* <CircularProgress color="inherit" /> */}
      <BeatLoader color="#fff" loading={true} size={20} margin={4} />
    </Backdrop>
  );
};
