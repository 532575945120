import { Group, User } from "../../models/peoples";

const types = {
  FETCH_PEOPLES: "FETCH_PEOPLES",
  FETCH_USER_AND_GROUPS: "FETCH_USER_AND_GROUPS",
  ADD_USER_IN_GROUP: "ADD_USER_IN_GROUP",
  DELETE_USER_IN_GROUP: "DELETE_USER_IN_GROUP",
  FETCH_USER_PHOTO: "FETCH_USER_PHOTO",
  FETCH_AD_USERS: "FETCH_AD_USERS"
};

export const fetchPeoplesRequest = `${types.FETCH_PEOPLES}_REQUEST`;
export const fetchPeoplesFailure = `${types.FETCH_PEOPLES}_FAILURE`;

export const fetchUserAndGroupsSuccess = `${types.FETCH_USER_AND_GROUPS}_SUCCESS`;

export const addUserInGroupRequest = `${types.ADD_USER_IN_GROUP}_REQUEST`;
export const addUserInGroupSuccess = `${types.ADD_USER_IN_GROUP}_SUCCESS`;
export const addUserInGroupFailure = `${types.ADD_USER_IN_GROUP}_FAILURE`;

export const deleteUserInGroupSuccess = `${types.DELETE_USER_IN_GROUP}_SUCCESS`;


export const fetchADUsersRequest = `${types.FETCH_AD_USERS}_REQUEST`;
export const fetchADUsersSuccess = `${types.FETCH_AD_USERS}_SUCCESS`;

export const fetchUserPhotoSuccess = `${types.FETCH_USER_PHOTO}_SUCCESS`;
export const dispatchFetchPeoplesRequest = () => ({
  type: fetchPeoplesRequest,
});
export const dispatchFetchPeoplesFailure = () => ({
  type: fetchPeoplesFailure,
});
export const dispatchFetchUsersAndGroupsSuccess = (groups: Array<Group>) => ({
  type: fetchUserAndGroupsSuccess,
  groups,
});
export const dispatchAddUserInGroupRequest = (groupId: string) => ({
  type: addUserInGroupRequest,
  groupId,
});
export const dispatchAddUserInGroupSuccess = () => ({
  type: addUserInGroupSuccess,
});
export const dispatchAddUserInGroupFailure = () => ({
  type: addUserInGroupFailure,
});
export const dispatchDeleteUserInGroupSuccess = () => ({
  type: deleteUserInGroupSuccess,
});
export const dispatchFetchUserPhotoSuccess = (user: User, data: string) => ({
  type: fetchUserPhotoSuccess,
  user,
  data,
});
export const dispatchFetchADUsersRequest = () => ({
  type: fetchADUsersRequest
})
export const dispatchFetchADUsersSuccess = (adUsers: any) => ({
  type: fetchADUsersSuccess,
  data: adUsers
})
