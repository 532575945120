const darkBlack = "rgb(36, 40, 44)";
const warningLight = "rgba(253, 200, 69, .3)";
const warningMain = "rgba(253, 200, 69, .5)";
const warningDark = "rgba(253, 200, 69, .7)";

// spacing
const spacing = 8;
export const getDefaultPalette = {
  primary: { main: "#F5B946", contrastText: "#fff" },
  secondary: { main: "#5B5B5B" },
  common: {
    black: "#000000",
    darkBlack,
  },
  warning: {
    light: warningLight,
    main: warningMain,
    dark: warningDark,
  },
  // Used to shift a color's luminance by approximately
  // two indexes within its tonal palette.
  // E.g., shift from Red 500 to Red 300 or Red 700.
  tonalOffset: 0.2,
  background: {
    default: "#E5E5E5",
  },
  spacing,
};

export const setPalette = (palette: any) => ({
  primary: {
    main: `#${palette["primary-color"].hex}`,
    contrastText: `#${palette["text-color"].hex}`,
  },
  secondary: {
    main: `#${palette["secondary-color"].hex}`,
    contrastText: `#${palette["text-color"].hex}`,
  },
  common: {
    black: "#000000",
    darkBlack,
  },
  warning: {
    light: warningLight,
    main: warningMain,
    dark: warningDark,
  },
  // Used to shift a color's luminance by approximately
  // two indexes within its tonal palette.
  // E.g., shift from Red 500 to Red 300 or Red 700.
  tonalOffset: 0.2,
  background: {
    default: "#E5E5E5",
  },
  spacing,
});
