import { routerMiddleware } from "connected-react-router";
import { applyMiddleware, compose, createStore } from "redux";
import { enableBatching } from "redux-batched-actions";
import thunkMiddleware from "redux-thunk";

import history from "./history";
import rootReducer from "./reducers";

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
  }
}
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(
  // @ts-ignore
  enableBatching(rootReducer) /* preloadedState, */,
  {},
  composeEnhancers(applyMiddleware(thunkMiddleware, routerMiddleware(history)))
);
export { store };
