const types = {
  CREATE_REPORT: "CREATE_REPORT",
  DELETE_REPORT: "DELETE_REPORT",
  FETCH_PB_WORKSSPACE_REPORT: "FETCH_PB_WORKSSPACE_REPORT",
  VIEW_REPORT: "VIEW_REPORT",
  VIEW_AAD_REPORT: "VIEW_AAD_REPORT",
  LAST_VIEWED_REPORT: "LAST_VIEWED_REPORT",
  MOVE_REPORT: "MOVE_REPORT"
};

export const createReportRequest = `${types.CREATE_REPORT}_REQUEST`;
export const createReportSuccess = `${types.CREATE_REPORT}_SUCCESS`;
export const reportFailure = `${types.CREATE_REPORT}_FAILURE`;

export const viewReportRequest = `${types.VIEW_REPORT}_REQUEST`;
export const viewReportSuccess = `${types.VIEW_REPORT}_SUCCESS`;
export const resetReportDetails = `${types.VIEW_REPORT}_RESET`;

export const viewAadReportSuccess = `${types.VIEW_AAD_REPORT}_SUCCESS`;
export const viewAadReportFailure = `${types.VIEW_AAD_REPORT}_FAILURE`;

export const selectedReportDetails = "selectedReportDetails";

export const fetchPBWorkspaceReportRequest = `${types.FETCH_PB_WORKSSPACE_REPORT}_REQUEST`;
export const fetchPBWorkspaceReportSuccess = `${types.FETCH_PB_WORKSSPACE_REPORT}_SUCCESS`;

export const deleteReportRequest = `${types.DELETE_REPORT}_REQUEST`;
export const deleteReportSuccess = `${types.DELETE_REPORT}_SUCCESS`;

export const lastViewedReportRequest = `${types.LAST_VIEWED_REPORT}_REQUEST`;
export const lastViewedReportSuccess = `${types.LAST_VIEWED_REPORT}_SUCCESS`;
export const lastViewedReportFailure = `${types.LAST_VIEWED_REPORT}_FAILURE`;

export const moveReportRequest = `${types.MOVE_REPORT}_REQUEST`;
export const moveReportSuccess = `${types.MOVE_REPORT}_SUCCESS`;

export const dispatchCreateReportRequest = () => ({
  type: createReportRequest,
});
export const dispatchCreateReportSuccess = () => ({
  type: createReportSuccess,
});
export const dispatchreportFailure = () => ({
  type: reportFailure,
});

export const dispatchDeleteReportRequest = () => ({
  type: deleteReportRequest,
});
export const dispatchDeleteReportSuccess = () => ({
  type: deleteReportSuccess,
});

export const dispatchFetchPBWorkspaceReportRequest = () => ({
  type: fetchPBWorkspaceReportRequest,
});
export const dispatchFetchPBWorkspaceReportSuccess = (workspaces: any) => ({
  type: fetchPBWorkspaceReportSuccess,
  workspaces,
});
export const dispatchViewReportRequest = () => ({
  type: viewReportRequest,
});
export const dispatchResetReport = () => ({
  type: resetReportDetails,
});
export const dispatchViewReportSuccess = (reportdetails: any) => ({
  type: viewReportSuccess,
  reportdetails,
});
export const dispatchSelectedReport = (selectedReport: any) => ({
  type: selectedReportDetails,
  selectedReport,
});

export const dispatchViewAadReportSuccess = (reportdetails: any) => ({
  type: viewAadReportSuccess,
  reportdetails,
});

export const dispatchLastViewedReportRequest = () => ({
  type: lastViewedReportRequest,
});
export const dispatchLastViewedReportSuccess = (reports: any) => ({
  type: lastViewedReportSuccess,
  reports,
});
export const dispatchLastViewedReportFailure = () => ({
  type: lastViewedReportFailure,
});

export const dispatchViewAadReportFailure = (reportError: any) => ({
  type: viewAadReportFailure,
  reportError,
});
export const dispatchMoveReportRequest = () => ({
  type: moveReportRequest,
});

export const dispatchMoveReportSuccess = () => ({
  type: moveReportSuccess,
});