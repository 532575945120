const types = {
  FETCH_DATAWARE: "FETCH_DATAWARE",
  FETCH_RESOURCES: "FETCH_RESOURCES",
  FETCH_SERVICES: "FETCH_SERVICES",
  FETCH_PROFILE: "FETCH_PROFILE",
  FETCH_FILE_SYSTEMS: "FETCH_FILE_SYSTEMS",
  FETCH_DATASETS: "FETCH_DATASETS",
  FETCH_FAILURE: "FETCH_FAILURE",
  FETCH_SERVICE_URL: "FETCH_SERVICE_URL",
};

export const fetchRequestFailure = `${types.FETCH_FAILURE}`;
export const fetchDatawareRequest = `${types.FETCH_DATAWARE}_REQUEST`;
export const fetchDatawareFailure = `${types.FETCH_DATAWARE}_REQUEST`;

export const fetchResourcesSuccess = `${types.FETCH_RESOURCES}_SUCCESS`;

export const fetchDatawareServicesRequest = `${types.FETCH_SERVICES}_REQUEST`;
export const fetchDatawareServicesSuccess = `${types.FETCH_SERVICES}_SUCCESS`;

export const fetchFileSystemRequest = `${types.FETCH_FILE_SYSTEMS}_REQUEST`;
export const fetchFileSystemSuccess = `${types.FETCH_FILE_SYSTEMS}_SUCCESS`;

export const fetchDatasetsRequest = `${types.FETCH_DATASETS}_REQUEST`;
export const fetchDatasetsSuccess = `${types.FETCH_DATASETS}_SUCCESS`;

export const fetchDatawareServiceUrlSuccess = `${types.FETCH_SERVICE_URL}_SUCCESS`;
export const dispatchFetchDatwareRequest = () => ({
  type: fetchDatawareRequest,
});

export const dispatchFetchDatwareFailure = () => ({
  type: fetchDatawareFailure,
});

export const dispatchFetchResourcesSuccess = (data: any) => ({
  type: fetchResourcesSuccess,
  data,
});

export const dispatchFetchDatwareServicesRequest = () => ({
  type: fetchDatawareServicesRequest,
});

export const dispatchFetchServicesSuccess = (data: any) => ({
  type: fetchDatawareServicesSuccess,
  data,
});

export const dispatchFetchServiceUrlSuccess = (data: any) => ({
  type: fetchDatawareServiceUrlSuccess,
  data,
});

export const dispatchFileSystemRequest = () => ({
  type: fetchFileSystemRequest,
});

export const dispatchDatasetsRequest = () => ({
  type: fetchDatasetsRequest,
});

export const dispatchFetchFileSystemSuccess = (data: any) => ({
  type: fetchFileSystemSuccess,
  data,
});

export const dispatchDatasetsSuccess = (data: any) => ({
  type: fetchDatasetsSuccess,
  data,
});

export const dispatchFetchRequestFailure = () => ({
  type: fetchRequestFailure,
});
