import { Configuration, PopupRequest } from "@azure/msal-browser";

const {
  REACT_APP_AD_CLIENT_ID,
  REACT_APP_AUTH_B2C_CLIENT_ID,
  REACT_APP_AUTH_DOMAIN,
  REACT_APP_AUTH_INSTANCE,
  REACT_APP_Sigin_Policy,
  REACT_APP_AUTH_B2B_TENANT,
} = process.env;
console.log(process.env);
export const REDIRECT_URL = "/home";
// Config object to be passed to Msal on creation

export const msalConfig: Configuration = {
  auth: {
    authority: `https://login.microsoftonline.com/${REACT_APP_AUTH_B2B_TENANT}`,
    clientId: REACT_APP_AD_CLIENT_ID || "common",
    postLogoutRedirectUri: window.location.origin,
    redirectUri: `${window.location.origin}${REDIRECT_URL}`,
  },
  cache: {
    cacheLocation: "localStorage",
    storeAuthStateInCookie: false,
  },
};

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const loginRequest: PopupRequest = {
  scopes: [
    "openid",
    "https://analysis.windows.net/powerbi/api/Report.Read.All",
  ],
};
export const b2bLoginRequest: PopupRequest = {
  scopes: [`${REACT_APP_AD_CLIENT_ID}/user_impersonation`],
};
// Add here the endpoints for MS Graph API services you would like to use.
export const graphConfig = {
  graphMeEndpoint: "https://graph.microsoft.com/v1.0/me",
};

export const b2cmsalConfig = {
  auth: {
    clientId: REACT_APP_AUTH_B2C_CLIENT_ID,
    authority: `${REACT_APP_AUTH_INSTANCE}/${REACT_APP_AUTH_DOMAIN}/${REACT_APP_Sigin_Policy}`,
    knownAuthorities: [
      `${REACT_APP_AUTH_INSTANCE?.substring(
        REACT_APP_AUTH_INSTANCE.indexOf("//") + 2
      )}`,
    ],
    redirectUri: `${window.location.origin}${REDIRECT_URL}`,
    postLogoutRedirectUri: window.location.origin,
  },
  cache: {
    cacheLocation: "sessionStorage",
    storeAuthStateInCookie: true,
  },
};

export const b2cmsalConfigCreation = (REACT_APP_Sigin_Policy: string) => {
  return {
    auth: {
      clientId: REACT_APP_AUTH_B2C_CLIENT_ID,
      authority: `${REACT_APP_AUTH_INSTANCE}/${REACT_APP_AUTH_DOMAIN}/${REACT_APP_Sigin_Policy}`,
      knownAuthorities: [
        `${REACT_APP_AUTH_INSTANCE?.substring(
          REACT_APP_AUTH_INSTANCE.indexOf("//") + 2
        )}`,
      ],
      redirectUri: `${window.location.origin}${REDIRECT_URL}`,
      postLogoutRedirectUri: window.location.origin,
    },
    cache: {
      cacheLocation: "sessionStorage",
      storeAuthStateInCookie: true,
    },
  };
};
