import {
  changeThemePaletteRequest,
  changeThemePaletteSuccess,
} from "../actions/theme";
import { getDefaultPalette, setPalette } from "./utils";

export interface authState {
  palette: {};
  gradient: string;
}
export const getInitialState = () => {
  return {
    palette: getDefaultPalette,
    gradient:
      "linear-gradient(269.93deg, rgb(95, 162, 155) -2.15%, rgb(66, 114, 169) 84.21%, rgb(24, 59, 105) 116.28%)",
  };
};

const themeReducer = (state: authState = getInitialState(), action: any) => {
  switch (action.type) {
    case changeThemePaletteRequest:
      return { ...state, isAdminUserChecking: true };
    case changeThemePaletteSuccess:
      const settings = action.palette;
      const { clientsettings } = settings;
      const currentclient = clientsettings.find(
        (p: any) => p.subdomainhostname === window.location.hostname
      );
      document.title = currentclient.title || "Insights";
      const palette = JSON.parse(currentclient.colorpalette);
      const gradient = JSON.parse(currentclient.gradient);
      return {
        ...state,
        palette: setPalette(palette),
        gradient: gradient && gradient.style,
      };
    default:
      return state;
  }
};

export default themeReducer;
