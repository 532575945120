import { filterB2CUserScccess } from "../actions/audiences";
import {
  acceptPrivacyRequest,
  createB2cAdminUserRequest,
  deleteB2cAdminUserRequest,
  downloadLogoFailure,
  downloadLogoRequest,
  downloadLogoSuccess,
  fetchAdminUserFailure,
  fetchAdminUserRequest,
  fetchAdminUsersRequest,
  fetchAdminUsersSuccess,
  fetchAdminUserSuccess,
  fetchB2CAdminUsersRequest,
  fetchB2CAdminUsersSuccess,
  fetchIconLinkImagesSuccess,
  fetchIconLinksRequest,
  fetchIconLinksSuccess,
  fetchPrivacyRequest,
  fetchPrivacySuccess,
  fetchProfileFailure,
  fetchProfileRequest,
  fetchProfileSuccess,
  fetchUserSettingsRequest,
  fetchUserSettingsSuccess,
  getGraphDataSuccess,
  iconLinksRequest,
  iconLinksSuccess,
  signInFailure,
  signInRequest,
  signInSuccess,
  uploadLogoRequest
} from "../actions/auth";

export interface authState {
  isAuthorized: boolean;
  isFetching: boolean;
  signInError: string | null;
  currentUser: Record<string, unknown> | null;
  isAdminUserChecking: boolean;
}
export const getInitialState = () => ({
  isAuthorized: false,
  isFetching: false,
  signInError: null,
  currentUser: null,
  adminUser: null,
  adminUsers: [],
  b2cUsers: [],
  iconLinks: [],
  tempb2cUsers: [],
  isAdmin: null,
  isAdminUserChecking: false,
  settings: null,
  logo: null,
  isPrivacyAccept: null,
  error: null,
  logout: false,
  profile: null,
  isShowService: true,
  userManagementEnabled: true
});

const authReducer = (state: authState = getInitialState(), action: any) => {
  switch (action.type) {
    case signInRequest:
    case fetchAdminUserRequest:
    case fetchAdminUsersRequest:
    case createB2cAdminUserRequest:
      return { ...state, isAdminUserChecking: true, isFetching: true };
    case deleteB2cAdminUserRequest:
    case fetchUserSettingsRequest:
    case uploadLogoRequest:
    case downloadLogoRequest:
    case fetchPrivacyRequest:
    case acceptPrivacyRequest:
    case fetchIconLinksRequest:
    case iconLinksRequest:
    case fetchProfileRequest:
      return { ...state, isFetching: true };
    case fetchB2CAdminUsersRequest:
      return {
        ...state,
        isFetchingADusers: true,
      };
    case signInSuccess:
      return {
        ...state,
        isFetching: false,
        user: action.user,
      };
    case getGraphDataSuccess:
      return {
        ...state,
        currentUser: action.graphData,
      };
    case fetchAdminUserSuccess:
      return {
        ...state,
        isAdminUserChecking: false,
        adminUser: action.adminUser,
        isFetching: false,
        isAdmin: !(action.adminUser.objectId === null),
      };
    case fetchAdminUsersSuccess:
      return {
        ...state,
        isAdminUserChecking: false,
        isFetching: false,
        adminUsers: action.adminUsers,
      };
    case fetchB2CAdminUsersSuccess:
      return {
        ...state,
        isFetching: false,
        isFetchingADusers: false,
        tempb2cUsers: action.b2cUsers,
        b2cUsers: action.b2cUsers,
      };
    case fetchUserSettingsSuccess:
      return {
        ...state,
        isFetching: false,
        settings: action.data,
      };
    case downloadLogoSuccess:
      return {
        ...state,
        isFetching: false,
        logo: action.logo,
      };
    case fetchPrivacySuccess:
      localStorage.setItem("privacy", !(action.id === 0) ? "true" : "false");
      return {
        ...state,
        isPrivacyAccept: !(action.id === 0),
        isFetching: false,
      };
    case fetchIconLinksSuccess: {
      //@ts-ignore
      const { iconLinks } = state;
      const newIconLinks = action.data.map((iconLink: any) => {
        const iconLinkExist = iconLinks.find(
          (link: any) => link.iconId === iconLink.iconId
        );
        return {
          ...iconLink,
          icon: iconLinkExist ? iconLinkExist.icon : null,
        };
      });
      return {
        ...state,
        iconLinks: newIconLinks,
        isFetching: false,
      };
    }
    case iconLinksSuccess:
      return {
        ...state,
        isFetching: false,
      };
    case fetchIconLinkImagesSuccess:
      //@ts-ignore
      const { iconLinks } = state;
      const updatedIconImages = iconLinks.map((iconLink: any) => {
        const imageArray = action.data.find(
          (link: any) => link.iconId === iconLink.iconId
        ).imageArray;
        return {
          ...iconLink,
          icon: `data:image/png;base64,${imageArray}`,
        };
      });
      return {
        ...state,
        iconLinks: updatedIconImages,
        isFetching: false,
      };
    case fetchAdminUserFailure:
    case fetchProfileFailure:
      return {
        ...state,
        isFetching: false,
      };
    case downloadLogoFailure:
      return {
        ...state,
        error: true,
        isFetching: false,
      };
    case filterB2CUserScccess:
      //@ts-ignore
      const { tempb2cUsers } = state;
      if (action.searchValue) {
        const filterUsers = tempb2cUsers.filter(
          (p: any) =>
            p.displayName
              .toLowerCase()
              .includes(action.searchValue.toLowerCase()) ||
            (p.email &&
              p.email.toLowerCase().includes(action.searchValue.toLowerCase()))
        );
        return { ...state, b2cUsers: filterUsers };
      } else {
        return { ...state, b2cUsers: tempb2cUsers };
      }
    case signInFailure:
      return { ...state, isFetching: false, logout: true };
    case fetchProfileSuccess:
      const user = action.data;
      return {
        ...state,
        isFetching: false,
        profile: action.data,
        isAdmin: user ? user.groups.includes("Administrators") : false,
        userManagementEnabled: user.userManagementEnabled,
        isShowService:
          user &&
            user.groups &&
            user.groups.length === 1 &&
            (user.groups.includes("Consumers") ||
              user.groups.includes("consumers"))
            ? false
            : true,
      };
    default:
      return state;
  }
};

export default authReducer;
