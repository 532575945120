import { Entity } from "../../models/privacy";

const types = {
  FETCH_PRIVACY: "FETCH_PRIVACY",
  FETCH_ENTITIES: "FETCH_ENTITIES",
  SEARCH_PII_DATA: "SEARCH_PII_DATA",
  UPDATE_PII_DATA: "UPDATE_PII_DATA",
  FETCH_SENDGRID_KEY: "FETCH_SENDGRID_KEY",
  POST_SENDGRID_KEY: "POST_SENDGRID_KEY",
  ERASE_DATA: "ERASE_DATA",
};

export const fetchPrivacyRequest = `${types.FETCH_PRIVACY}_REQUEST`;
export const fetchPrivacyFailure = `${types.FETCH_PRIVACY}_FAILURE`;
export const fetchEntitiesSuccess = `${types.FETCH_ENTITIES}_SUCCESS`;

export const searchPIIDataRequest = `${types.SEARCH_PII_DATA}_REQUEST`;
export const searchPIIDataFailure = `${types.SEARCH_PII_DATA}_FAILURE`;
export const searchPIIDataSuccess = `${types.SEARCH_PII_DATA}_SUCCESS`;
export const updatePIIDataSuccess = `${types.UPDATE_PII_DATA}_SUCCESS`;

export const fetchSendGridAPIKeyRequest = `${types.FETCH_SENDGRID_KEY}_REQUEST`;
export const fetchSendGridAPIKeySuccess = `${types.FETCH_SENDGRID_KEY}_SUCCESS`;

export const postSendGridAPIKeyRequest = `${types.FETCH_PRIVACY}_REQUEST`;
export const postSendGridAPIKeySuccess = `${types.FETCH_ENTITIES}_SUCCESS`;

export const postEarseDataRequest = `${types.ERASE_DATA}_REQUEST`;
export const postEarseDataSuccess = `${types.ERASE_DATA}_SUCCESS`;

export const FAILURE = "FAILURE";

export const dispatchFetchPrivacyRequest = () => ({
  type: fetchPrivacyRequest,
});
export const dispatchFetchPrivacyFailure = () => ({
  type: fetchPrivacyFailure,
});
export const dispatchFetchEntitiesSuccess = (entities: Array<Entity>) => ({
  type: fetchEntitiesSuccess,
  entities,
});

export const dispatchSearchPIIDataRequest = () => ({
  type: searchPIIDataRequest,
});
export const dispatchSearchPIIDataFailure = () => ({
  type: searchPIIDataFailure,
});
export const dispatchSearchPIIDataSuccess = (data: any) => ({
  type: searchPIIDataSuccess,
  data,
});

export const dispatchUpdatePIIDataSuccess = () => ({
  type: updatePIIDataSuccess,
});
export const dispatchFetchSendGridAPIKeyRequest = () => ({
  type: fetchSendGridAPIKeyRequest,
});
export const dispatchFetchSendGridAPIKeySuccess = (data: any) => ({
  type: fetchSendGridAPIKeySuccess,
  data,
});
export const dispatchPostSendGridAPIKeyRequest = () => ({
  type: postSendGridAPIKeyRequest,
});
export const dispatchPostSendGridAPIKeySuccess = () => ({
  type: postSendGridAPIKeySuccess,
});
export const dispatchFailure = () => ({
  type: FAILURE,
});
export const dispatchPostEarseDataRequest = () => ({
  type: postEarseDataRequest,
});
export const dispatchPostEarseDataSuccess = () => ({
  type: postEarseDataSuccess,
});
