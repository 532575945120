import { lazy } from "react";
import { ROUTES } from "./constants";

const AddAudiences = lazy(() =>
  import(/* webpackPrefetch: true */ "../features/audiences/index").then(
    (module) => ({
      default: module.default,
    })
  )
);

const ServiceAccount = lazy(() =>
  import(
    /* webpackPrefetch: true */ "../features/configure/serviceaccount"
  ).then((module) => ({
    default: module.default,
  }))
);

const PortalSelection = lazy(() =>
  import(
    /* webpackPrefetch: true */ "../views/portal-selection/portal-selection"
  ).then((module) => ({
    default: module.default,
  }))
);
const NoAuthLayout = lazy(() =>
  import(/* webpackPrefetch: true */ "../layouts").then((module) => ({
    default: module.NoAuthLayout,
  }))
);

const AdminLayout = lazy(() =>
  import(/* webpackPrefetch: true */ "../layouts").then((module) => ({
    default: module.AdminLayout,
  }))
);
const ViewReport = lazy(() =>
  import(
    /* webpackPrefetch: true */ "../features/audiences/view-report/view-report"
  ).then((module) => ({
    default: module.default,
  }))
);
const UserSettings = lazy(() =>
  import(
    /* webpackPrefetch: true */ "../features/configure/user-settings"
  ).then((module) => ({
    default: module.default,
  }))
);
const Configuration = lazy(() =>
  import(/* webpackPrefetch: true */ "../Team-Configuration").then(
    (module) => ({
      default: module.default,
    })
  )
);
const Policy = lazy(() =>
  import(
    /* webpackPrefetch: true */ "../features/privacy-policy/privacy-policy"
  ).then((module) => ({
    default: module.default,
  }))
);
const Start = lazy(() =>
  import(/* webpackPrefetch: true */ "../views/start").then((module) => ({
    default: module.default,
  }))
);
const Configure = lazy(() =>
  import(/* webpackPrefetch: true */ "../views/configure").then((module) => ({
    default: module.default,
  }))
);
const Login = lazy(() =>
  import(/* webpackPrefetch: true */ "../views/login").then((module) => ({
    default: module.default,
  }))
);
const TeamsContext = lazy(() =>
  import(/* webpackPrefetch: true */ "../features/teams/teams-contex").then(
    (module) => ({
      default: module.TeamsContext,
    })
  )
);
const Dataware = lazy(() =>
  import(/* webpackPrefetch: true */ "../dataware/views/home").then(
    (module) => ({
      default: module.default,
    })
  )
);
const Peoples = lazy(() =>
  import(/* webpackPrefetch: true */ "../dataware/peoples").then((module) => ({
    default: module.default,
  }))
);
const INGESTION = lazy(() =>
  import(/* webpackPrefetch: true */ "../IngestionFramework").then(
    (module) => ({
      default: module.default,
    })
  )
);

const Privacy = lazy(() =>
  import(/* webpackPrefetch: true */ "../privacy").then((module) => ({
    default: module.default,
  }))
);

const WIKI = lazy(() =>
  import(/* webpackPrefetch: true */ "../wikidoc").then((module) => ({
    default: module.default,
  }))
);

const PAGE_NOT_FOUND = lazy(() =>
  import(/* webpackPrefetch: true */ "../layouts/404").then((module) => ({
    default: module.default,
  }))
);

export const appRoutes = [
  [ROUTES.PORTAL, NoAuthLayout, PortalSelection],
  [ROUTES.AUDIENCES, AdminLayout, AddAudiences],
  [ROUTES.SERVICEACCOUNT, AdminLayout, ServiceAccount],
  [ROUTES.VIEW_REPORT, AdminLayout, ViewReport],
  [ROUTES.SETTINGS, AdminLayout, UserSettings],
  [ROUTES.CONFIGURATION, NoAuthLayout, Configuration],
  [ROUTES.POLICY, AdminLayout, Policy],
  [ROUTES.START, NoAuthLayout, Start],
  [ROUTES.CONFIGURE, NoAuthLayout, Configure],
  [ROUTES.LOGIN, NoAuthLayout, Login],
  [ROUTES.TEAMS_CONTEXT, AdminLayout, TeamsContext],
  [ROUTES.WIKI, AdminLayout, WIKI],
  [ROUTES.PAGE_NOT_FOUND, NoAuthLayout, PAGE_NOT_FOUND],
  [
    ROUTES.DATAWARE,
    process.env.REACT_APP_DataPlatform_Enabled === "false"
      ? PAGE_NOT_FOUND
      : AdminLayout,
    Dataware,
  ],
  [
    ROUTES.USERS_AND_GROUPS,
    process.env.REACT_APP_DataPlatform_Enabled === "false"
      ? PAGE_NOT_FOUND
      : AdminLayout,
    Peoples,
  ],
  [
    ROUTES.INGESTION,
    process.env.REACT_APP_IngestionFramework_Enabled === "false"
      ? PAGE_NOT_FOUND
      : AdminLayout,
    INGESTION,
  ],
  [
    ROUTES.PRIVACY,
    process.env.REACT_APP_PrivacyPlatform_Enabled === "false"
      ? PAGE_NOT_FOUND
      : AdminLayout,
    Privacy,
  ],
  [
    ROUTES.PRIVACY_SEARCH,
    process.env.REACT_APP_PrivacyPlatform_Enabled === "false"
      ? PAGE_NOT_FOUND
      : AdminLayout,
    Privacy,
  ],
];
