import { datawareState, IDatawareAction } from "../../models/dataware";
import {
  fetchDatasetsRequest,
  fetchDatasetsSuccess,
  fetchDatawareFailure,
  fetchDatawareRequest,
  fetchDatawareServicesRequest,
  fetchDatawareServicesSuccess,
  fetchDatawareServiceUrlSuccess,
  fetchFileSystemRequest,
  fetchFileSystemSuccess,
  fetchResourcesSuccess,
} from "../actions/dataware";

export const getInitialState = () => ({
  isFetching: false,
  resources: [],
  services: [],
  fileSystems: [],
  datasets: [],
  urlData: null,
});

const datawareReducer = (
  state: datawareState = getInitialState(),
  action: IDatawareAction
) => {
  switch (action.type) {
    case fetchDatawareRequest:
    case fetchDatawareServicesRequest:
    case fetchFileSystemRequest:
    case fetchDatasetsRequest:
      return { ...state, isFetching: true };
    case fetchResourcesSuccess:
      return {
        ...state,
        isFetching: false,
        resources: action.data,
      };
    case fetchDatawareServicesSuccess:
      return {
        ...state,
        isFetching: false,
        services: action.data,
      };
    case fetchDatawareServiceUrlSuccess:
      return {
        ...state,
        isFetching: false,
        urlData: action.data,
      };

    case fetchDatawareFailure:
      return { ...state, isFetching: false };
    case fetchFileSystemSuccess:
      return { ...state, fileSystems: action.data, isFetching: false };
    case fetchDatasetsSuccess:
      return { ...state, datasets: action.data, isFetching: false };
    default:
      return state;
  }
};

export default datawareReducer;
