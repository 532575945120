import React, { createContext } from "react";
import { reactPlugin } from "./";

const AppInsightsContext = createContext(reactPlugin);

const AppInsightsContextProvider = ({ children }: any) => {
  return (
    <AppInsightsContext.Provider value={reactPlugin}>
      {children}
    </AppInsightsContext.Provider>
  );
};

export { AppInsightsContext, AppInsightsContextProvider };
