const types = {
  SIGN_IN: "SIGN_IN",
  RESTORE_PASSWORD: "RESTORE_PASSWORD",
  GRAPH_DATA: "GRAPH_DATA",
  ADMIN_USER: "ADMIN_USER",
  ADMIN_USERS: "ADMIN_USERS",
  B2C_ADMIN_USERS: "B2C_ADMIN_USERS",
  CREATE_B2C_ADMIN_USERS: "CREATE_B2C_ADMIN_USERS",
  DELETE_B2C_ADMIN_USERS: "DELETE_B2C_ADMIN_USERS",
  USER_SETTINGS: "USER_SETTINGS",
  CREATE_USER_SETTINGS: "CREATE_USER_SETTINGS",
  UPLOAD_LOGO: "UPLOAD_LOGO",
  DOWNLOAD_LOGO: "DOWNLOAD_LOGO",
  GET_PRIVACY: "GET_PRIVACY",
  ACCEPT_PRIVACY: "ACCEPT_PRIVACY",
  GET_ICON_LINKS: "GET_ICON_LINKS",
  ICON_LINKS: "ICON_LINKS",
  ICON_LINK_IMAGES: "ICON_LINK_IMAGES",
  FETCH_PROFILE: "FETCH_PROFILE",
};

export const signInRequest = `${types.SIGN_IN}_REQUEST`;
export const signInSuccess = `${types.SIGN_IN}_SUCCESS`;
export const signInFailure = `${types.SIGN_IN}_FAILURE`;

export const getGraphData = `${types.GRAPH_DATA}_REQUEST`;
export const getGraphDataSuccess = `${types.GRAPH_DATA}_SUCCESS`;

export const restorePasswordRequest = `${types.RESTORE_PASSWORD}_REQUEST`;

export const restorePasswordSuccess = `${types.RESTORE_PASSWORD}_SUCCESS`;
export const restorePasswordFailure = `${types.RESTORE_PASSWORD}_FAILURE`;

export const fetchAdminUserRequest = `${types.ADMIN_USER}_REQUEST`;
export const fetchAdminUserSuccess = `${types.ADMIN_USER}_SUCCESS`;
export const fetchAdminUserFailure = `${types.ADMIN_USER}_FAILURE`;

export const fetchAdminUsersRequest = `${types.ADMIN_USERS}_REQUEST`;
export const fetchAdminUsersSuccess = `${types.ADMIN_USERS}_SUCCESS`;

export const fetchB2CAdminUsersRequest = `${types.B2C_ADMIN_USERS}_REQUEST`;
export const fetchB2CAdminUsersSuccess = `${types.B2C_ADMIN_USERS}_SUCCESS`;

export const createB2cAdminUserRequest = `${types.CREATE_B2C_ADMIN_USERS}_REQUEST`;
export const createB2cAdminUserSuccess = `${types.CREATE_B2C_ADMIN_USERS}_SUCCESS`;

export const deleteB2cAdminUserRequest = `${types.DELETE_B2C_ADMIN_USERS}_REQUEST`;
export const deleteB2cAdminUserSuccess = `${types.DELETE_B2C_ADMIN_USERS}_SUCCESS`;

export const fetchUserSettingsRequest = `${types.USER_SETTINGS}_REQUEST`;
export const fetchUserSettingsSuccess = `${types.USER_SETTINGS}_SUCCESS`;

export const createUserSettingsRequest = `${types.CREATE_USER_SETTINGS}_REQUEST`;
export const createUserSettingsSuccess = `${types.CREATE_USER_SETTINGS}_SUCCESS`;

export const uploadLogoRequest = `${types.UPLOAD_LOGO}_REQUEST`;
export const uploadLogoSuccess = `${types.UPLOAD_LOGO}_SUCCESS`;

export const downloadLogoRequest = `${types.DOWNLOAD_LOGO}_REQUEST`;
export const downloadLogoSuccess = `${types.DOWNLOAD_LOGO}_SUCCESS`;
export const downloadLogoFailure = `${types.DOWNLOAD_LOGO}_FAILURE`;

export const fetchPrivacyRequest = `${types.GET_PRIVACY}_REQUEST`;
export const fetchPrivacySuccess = `${types.GET_PRIVACY}_SUCCESS`;

export const acceptPrivacyRequest = `${types.ACCEPT_PRIVACY}_REQUEST`;
export const acceptPrivacySuccess = `${types.ACCEPT_PRIVACY}_SUCCESS`;

export const fetchIconLinksRequest = `${types.GET_ICON_LINKS}_REQUEST`;
export const fetchIconLinksSuccess = `${types.GET_ICON_LINKS}_SUCCESS`;

export const iconLinksRequest = `${types.ICON_LINKS}_REQUEST`;
export const iconLinksSuccess = `${types.ICON_LINKS}_SUCCESS`;

export const fetchIconLinkImagesRequest = `${types.ICON_LINK_IMAGES}_REQUEST`;
export const fetchIconLinkImagesSuccess = `${types.ICON_LINK_IMAGES}_SUCCESS`;

export const fetchProfileRequest = `${types.FETCH_PROFILE}_REQUEST`;
export const fetchProfileSuccess = `${types.FETCH_PROFILE}_SUCCESS`;
export const fetchProfileFailure = `${types.FETCH_PROFILE}_FAILURE`;

export const dispatchSignInFailure = () => ({
  type: signInFailure,
});
export const dispatchSignInSuccess = () => ({
  type: signInSuccess,
});

export const dispatchgetGraphDataSuccess = (data: any) => ({
  type: getGraphDataSuccess,
  graphData: data,
});
export const dispatchFetchAdminUserRequest = () => ({
  type: fetchAdminUserRequest,
});
export const dispatchFetchAdminUserSuccess = (data: any) => ({
  type: fetchAdminUserSuccess,
  adminUser: data,
});
export const dispatchFetchAdminUserFailure = () => ({
  type: fetchAdminUserFailure,
});

export const dispatchFetchAdminUsersRequest = () => ({
  type: fetchAdminUsersRequest,
});
export const dispatchFetchAdminUsersSuccess = (data: any) => ({
  type: fetchAdminUsersSuccess,
  adminUsers: data,
});

export const dispatchFetchB2CAdminUsersRequest = () => ({
  type: fetchB2CAdminUsersRequest,
});
export const dispatchFetchB2CAdminUsersSuccess = (data: any) => ({
  type: fetchB2CAdminUsersSuccess,
  b2cUsers: data,
});

export const dispatchCreateB2CAdminUsersRequest = () => ({
  type: createB2cAdminUserRequest,
});
export const dispatchCreateB2CAdminUsersSuccess = (data: any) => ({
  type: createB2cAdminUserSuccess,
  b2cUser: data,
});
export const dispatchDeleteB2CAdminUsersRequest = () => ({
  type: deleteB2cAdminUserRequest,
});

export const dispatchfetchUserSettingsRequest = () => ({
  type: fetchUserSettingsRequest,
});
export const dispatchfetchUserSettingsSuccess = (data: any) => ({
  type: fetchUserSettingsSuccess,
  data,
});
export const dispatchCreateUserSettingsRequest = () => ({
  type: createUserSettingsRequest,
});

export const dispatchDownloadLogoSuccess = (data: any) => ({
  type: downloadLogoSuccess,
  logo: data,
});
export const dispatchDownloadLogoRequest = () => ({
  type: downloadLogoRequest,
});
export const dispatchDownloadLogoFailure = () => ({
  type: downloadLogoFailure,
});

export const dispatchUploadLogoRequest = () => ({
  type: uploadLogoRequest,
});
export const dispatchUploadLogoSuccess = () => ({
  type: uploadLogoSuccess,
});

export const dispatchPrivacyRequest = () => ({
  type: fetchPrivacyRequest,
});
export const dispatchPrivacySuccess = (id: string) => ({
  type: fetchPrivacySuccess,
  id,
});

export const dispatchAcceptPrivacyRequest = () => ({
  type: acceptPrivacyRequest,
});
export const dispatchAcceptPrivacySuccess = (id: string) => ({
  type: acceptPrivacySuccess,
  id,
});

export const dispatchGetIconLinksRequest = () => ({
  type: fetchIconLinksRequest,
});
export const dispatchGetIconLinksSuccess = (data: any) => ({
  type: fetchIconLinksSuccess,
  data,
});

export const dispatchIconLinksRequest = () => ({
  type: iconLinksRequest,
});
export const dispatchIconLinksSuccess = () => ({
  type: iconLinksSuccess,
});

export const dispatchFetchIconLinkImagesRequest = () => ({
  type: fetchIconLinkImagesRequest,
});
export const dispatchFetchIconLinkImagesSuccess = (data: any) => ({
  type: fetchIconLinkImagesSuccess,
  data,
});

export const dispatchFetchProfileRequest = () => ({
  type: fetchProfileRequest,
});
export const dispatchFetchProfileSuccess = (data: any) => ({
  type: fetchProfileSuccess,
  data,
});
export const dispatchFetchProfileFailure = () => ({
  type: fetchProfileFailure,
});

export const logout = "LOGOUT";
