import { Configuration, PublicClientApplication } from "@azure/msal-browser";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import App from "./App";
import { dataPortalApi } from "./api/utils";
import "./assets/main.scss";
import {
  b2bLoginRequest,
  b2cmsalConfigCreation,
  loginRequest,
  msalConfig,
} from "./authConfig";
import { store } from "./redux/store";
import reportWebVitals from "./reportWebVitals";
import { isB2B } from "./utils/check-teams";

let msalInstance = new PublicClientApplication(msalConfig as Configuration);

const preparedData = async () => {
  const res = await dataPortalApi({
    method: "GET",
    url: `/user/settings`,
  });
  if (res.data) {
    const { clientsettings } = res.data;
    let config;
    if (clientsettings.length > 0) {
      const currentclient = clientsettings.find(
        (p: any) => p.subdomainhostname === window.location.hostname
      );
      config = b2cmsalConfigCreation(currentclient.signinpolicy);
      msalInstance = new PublicClientApplication(config as Configuration);
    } else {
      config = b2cmsalConfigCreation(
        process.env.REACT_APP_Sigin_Policy as string
      );
      msalInstance = new PublicClientApplication(config as Configuration);
    }
  }
  // MSAL imports
  ReactDOM.render(
    <Provider store={store}>
      <App pca={msalInstance} />
    </Provider>,
    document.getElementById("root")
  );
};
preparedData();

export const acquireAccessToken = async () => {
  const activeAccount = msalInstance.getActiveAccount();
  const accounts = msalInstance.getAllAccounts();
  try {
    if (activeAccount || accounts[0]) {
      const updatedloginRequest = isB2B ? b2bLoginRequest : loginRequest;
      const request = {
        ...updatedloginRequest,
        account: activeAccount || accounts[0],
      };
      const authResult = await msalInstance.acquireTokenSilent(request);

      if (isB2B) {
        return authResult.accessToken;
      }
      return authResult.idToken;
    } else {
      msalInstance.loginRedirect();
    }
  } catch (e) {
    localStorage.clear();
    msalInstance.loginRedirect();
  }
  return "";
};
export const acquireTokenForPB = async () => {
  const activeAccount = msalInstance.getActiveAccount();
  const accounts = msalInstance.getAllAccounts();
  const request = {
    ...loginRequest,
    account: activeAccount || accounts[0],
  };
  const authResult = await msalInstance.acquireTokenSilent(request);

  return authResult.accessToken;
};
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log);
