import React from "react";
import { Route, Switch } from "react-router-dom";
import ErrorBoundary from "../ErrorBoundary";
import { appRoutes } from "./route-configs";

const renderRoutePage = (Layout: any, Component: any, props: any) => (
  <Layout>
    <ErrorBoundary {...props}>
      <Component {...props} />
    </ErrorBoundary>
  </Layout>
);
console.log(appRoutes);
export const AppRoutes = () => (
  <Switch>
    {appRoutes.map(([routeName, Layout, Component]) => (
      <Route
        key={routeName as string}
        path={routeName as string}
        exact
        render={(props) => renderRoutePage(Layout, Component, props)}
      />
    ))}
  </Switch>
);
