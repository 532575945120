import { IConfigureAction } from "../../models/configuration";
import {
  clearPowerBIServiceAccountState,
  createPBSrviceAccountRequest,
  editPBSrviceAccountRequest,
  exportAudienceDataRequest,
  exportAudienceDataSucess,
  fetchConfigurationsFailure,
  fetchConfigurationsRequest,
  fetchConfigurationsSuccess,
  getPBSrviceAccountRequestById,
  getPBSrviceAccountRequestByIdSuccess,
} from "../actions/configuration";

export const getInitialState = () => ({
  isFetching: false,
  isFetchingExportData: false,
  configurations: [],
  configuration: null,
  exportData: [],
});

const configureReducer = (
  state: any = getInitialState(),
  action: IConfigureAction
) => {
  switch (action.type) {
    case fetchConfigurationsRequest:
    case editPBSrviceAccountRequest:
    case getPBSrviceAccountRequestById:
    case createPBSrviceAccountRequest:
      return { ...state, isFetching: true };
    case exportAudienceDataRequest:
      return { ...state, isFetchingExportData: true };
    case fetchConfigurationsSuccess:
      return {
        ...state,
        isFetching: false,
        configurations: action.configurations,
      };
    case fetchConfigurationsFailure:
      return { ...state, isFetching: false, isFetchingExportData: false };

    case getPBSrviceAccountRequestByIdSuccess:
      return {
        ...state,
        isFetching: false,
        configuration: action.configuration,
      };
    case clearPowerBIServiceAccountState:
      return {
        ...state,
        configuration: null,
      };
    case exportAudienceDataSucess:
      return { ...state, exportData: action.data, isFetchingExportData: false };
    default:
      return state;
  }
};

export default configureReducer;
