import { push } from "connected-react-router";
import * as R from "ramda";
import { isUnauthorizedError } from "../api/utils";
import { ROUTES } from "../routes/constants";
import { dispatchSignInFailure, dispatchSignInSuccess } from "./actions/auth";
import { store } from "./store";

export const handleDataPortalHttpResponse = (error: any = null) => {
  if (R.pathEq(["response", "status"], 406)(error)) {
    store.dispatch(push(ROUTES.SIGN_IN));
  } else if (isUnauthorizedError(error)) {
    // Logout current user
    store.dispatch(dispatchSignInFailure());
  } else {
    // Sign in current user
    const { authReducer } = store.getState();

    if (!authReducer.isAuthorized) {
      store.dispatch(dispatchSignInSuccess());
    }

    // if (error) showErrorAlert(error);
  }
};

export const handle404HttpResponse = (error: any) => {
  if (R.pathEq(["response", "status"], 404, error)) {
    store.dispatch(push(ROUTES.NOT_FOUND));
  }
  return error;
};
