import { IPeoplesAction, peopleState } from "../../models/peoples";
import {
  addUserInGroupFailure,
  addUserInGroupRequest,
  addUserInGroupSuccess,
  deleteUserInGroupSuccess,
  fetchADUsersRequest,
  fetchADUsersSuccess,
  fetchPeoplesFailure,
  fetchPeoplesRequest,
  fetchUserAndGroupsSuccess,
  fetchUserPhotoSuccess
} from "../actions/peoples";


export const getInitialState = () => ({
  isFetching: false,
  isPosting: false,
  seletedGroupId: null,
  groups: [],
  photos: [],
  adUsers: []
});

const peoplesReducer = (
  state: peopleState = getInitialState(),
  action: IPeoplesAction
) => {
  switch (action.type) {
    case fetchPeoplesRequest:
    case fetchADUsersRequest:
      return { ...state, isFetching: true };
    case fetchUserAndGroupsSuccess:
      const groups = action.groups || [];
      if (state.photos.length > 0) {
        groups.forEach((group) => {
          group.users.forEach((user) => {
            const photoExistIndex = state.photos.findIndex(
              (photo) => photo.userId === user.userId
            );
            if (photoExistIndex !== -1) {
              user.photo = state.photos[photoExistIndex].photo;
            } else {
              user.photo = "";
            }
          });
        });
      }
      return {
        ...state,
        isFetching: false,
        groups,
      };
    case fetchUserPhotoSuccess: {
      const groups = [...state.groups];
      const groupIndex = groups.findIndex(
        (group) => group.groupId === action.user.groupId
      );
      const userIndex = groups[groupIndex].users.findIndex(
        (user) => user.userId === action.user.userId
      );
      state.groups[groupIndex].users[userIndex].photo = action.data;
      const photos = [...state.photos];
      photos.push({ userId: action.user.userId, photo: action.data });
      return {
        ...state,
        groups,
        photos,
      };
    }
    case fetchPeoplesFailure:
      return { ...state, isFetching: false };
    case addUserInGroupRequest:
      return {
        ...state,
        seletedGroupId: action.groupId,
        isPosting: true,
      };
    case addUserInGroupSuccess:
      return {
        ...state,
        seletedGroupId: null,
        isPosting: false,
      };
    case deleteUserInGroupSuccess:
      return {
        ...state,
        isFetching: false,
      };
    case addUserInGroupFailure:
      return { ...state, isPosting: false };
    case fetchADUsersSuccess:
      return { ...state, adUsers: action.data }
    default:
      return state;
  }
};

export default peoplesReducer;
