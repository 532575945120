//@ts-nocheck
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import PageNotFound from "./layouts/404";
class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    const { history } = props;
    history.listen((location, action) => {
      if (this.state["hasError"]) {
        this.setState({
          hasError: false,
        });
      }
    });
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error: any) {
    return { hasError: true, error };
  }
  render() {
    return this.state.hasError ? <PageNotFound /> : this.props.children;
  }
}
export default withRouter(ErrorBoundary);
