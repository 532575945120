import { Audience } from "../../models/audience";
import { B2CUserWithPagination } from "../../models/b2cusers";

const types = {
  FETCH_AUDIENCES: "FETCH_AUDIENCES",
  FETCH_AUDIENCE_BY_AUDIENCE_ID: "FETCH_AUDIENCE_BY_AUDIENCE_ID",
  FETCH_B2C_USERS: "FETCH_B2C_USERS",
  CREATE_AUDIENCES: "CREATE_AUDIENCES",
  DRAWER: "DRAWER",
  FETCH_AUDIENCE_USERS: "FETCH_AUDIENCE_USERS",
  DELETE_AUDIENCE_USER: "DELETE_AUDIENCE_USER",
  UPDATE_AUDIENCE: "UPDATE_AUDIENCE",
  DELETE_AUDIENCE: "DELETE_AUDIENCE",
  FILTER_B2C_USER: "FILTER_B2C_USER",
  SEARCH_B2C_USERS: "SEARCH_B2C_USERS",
  AUDIENCES_DRAG_END: "AUDIENCES_DRAG_END",
  UPDATE_USER_CANEDIT: "UPDATE_USER_CANEDIT",
  FETCH_THUMBNAILS: "FETCH_THUMBNAILS",
  CHECK_AUDIENCE_NAME: "CHECK_AUDIENCE_NAME",
  IMPORT_AUDIENCE: "IMPORT_AUDIENCE",
  RESET_AUDIENCE_CHECK: "RESET_AUDIENCE_CHECK",
  RESET_AD_USERS: "RESET_AD_USERS",
  UPDATE_AD_USERS: "UPDATE_AD_USERS"
};
export const openDrawer = `${types.DRAWER}_OPEN`;
export const closeDrawer = `${types.DRAWER}_CLOSE`;

export const fetchAudiencesRequest = `${types.FETCH_AUDIENCES}_REQUEST`;
export const fetchAudiencesSuccess = `${types.FETCH_AUDIENCES}_SUCCESS`;
export const fetchAudiencesFailure = `${types.FETCH_AUDIENCES}_FAILURE`;

export const fetchAudienceByAudienceIdSuccess = `${types.FETCH_AUDIENCE_BY_AUDIENCE_ID}_SUCCESS`;

export const fetchB2CUsersRequest = `${types.FETCH_B2C_USERS}_REQUEST`;
export const fetchB2CUsersSuccess = `${types.FETCH_B2C_USERS}_SUCCESS`;
export const searchB2CUsersSuccess = `${types.SEARCH_B2C_USERS}_SUCCESS`;

export const filterB2CUserScccess = `${types.FILTER_B2C_USER}_SUCCESS`;

export const createAudiencesRequest = `${types.CREATE_AUDIENCES}_REQUEST`;
export const createAudiencesSuccess = `${types.CREATE_AUDIENCES}_SUCCESS`;
export const createAudiencesFailure = `${types.CREATE_AUDIENCES}_FAILURE`;

export const fetchAudienceUsersRequest = `${types.FETCH_AUDIENCE_USERS}_REQUEST`;
export const fetchAudienceUsersSuccess = `${types.FETCH_AUDIENCE_USERS}_SUCCESS`;

export const deleteAudienceUserRequest = `${types.DELETE_AUDIENCE_USER}_REQUEST`;
export const deleteAudienceUserSuccess = `${types.DELETE_AUDIENCE_USER}_SUCCESS`;

export const updateAudienceRequest = `${types.UPDATE_AUDIENCE}_REQUEST`;
export const updateAudienceSuccess = `${types.UPDATE_AUDIENCE}_SUCCESS`;

export const deleteAudienceRequest = `${types.DELETE_AUDIENCE}_REQUEST`;
export const deleteAudienceSuccess = `${types.DELETE_AUDIENCE}_SUCCESS`;

export const audiencesDragEndSuccess = `${types.AUDIENCES_DRAG_END}_SUCCESS`;
export const updateUserCanedit = `${types.UPDATE_USER_CANEDIT}_SUCCESS`;

export const fetchThumbnailsRequest = `${types.FETCH_THUMBNAILS}_REQUEST`;
export const fetchThumbnailsSuccess = `${types.FETCH_THUMBNAILS}_SUCCESS`;
export const fetchThumbnailsFailure = `${types.FETCH_THUMBNAILS}_FAILURE`;

export const checkAudienceNameRequest = `${types.CHECK_AUDIENCE_NAME}_REQUEST`;
export const checkAudienceNameSuccess = `${types.CHECK_AUDIENCE_NAME}_SUCCESS`;

export const importAudienceRequest = `${types.IMPORT_AUDIENCE}_REQUEST`;
export const importAudienceSuccess = `${types.IMPORT_AUDIENCE}_SUCCESS`;

export const Failure = `FAILURE`;
export const updateADUsers = `${types.UPDATE_AD_USERS}_SUCCESS`;

export const resetAudienceCheck = `${types.RESET_AUDIENCE_CHECK}_REQUEST`;
export const resetADUsersSuccess = `${types.RESET_AD_USERS}_SUCCESS`;

export const dispatchFetchAudiencesRequest = () => ({
  type: fetchAudiencesRequest,
});
export const dispatchFetchAudiencesSuccess = (audiences: Array<Audience>) => ({
  type: fetchAudiencesSuccess,
  audiences,
});
export const dispatchfetchAudiencesFailure = () => ({
  type: fetchAudiencesFailure,
});

export const dispatchFetchAudienceByAudienceIdSuccess = (
  audience: Audience
) => ({
  type: fetchAudienceByAudienceIdSuccess,
  audience,
});

export const dispatchFetchB2CUsersRequest = () => ({
  type: fetchB2CUsersRequest,
});
export const dispatchFilterB2CUserSuccess = (searchValue: string) => ({
  type: filterB2CUserScccess,
  searchValue,
});
export const dispatchFetchB2CUsersSuccess = (
  usersResp: B2CUserWithPagination
) => ({
  type: fetchB2CUsersSuccess,
  usersResp,
});

export const dispatchCreateAudiencesRequest = () => ({
  type: createAudiencesRequest,
});
export const dispatchCreateAudiencesSuccess = () => ({
  type: createAudiencesSuccess,
});

export const dispatchOpenDrawer = () => ({
  type: openDrawer,
});
export const dispatchCloseDrawer = () => ({
  type: closeDrawer,
});

export const dispatchFetchAudienceUsersRequest = () => ({
  type: fetchAudienceUsersRequest,
});
export const dispatchFetchAudienceUsersSuccess = (
  usersResp: B2CUserWithPagination,
  audienceId: string
) => ({
  type: fetchAudienceUsersSuccess,
  usersResp,
  audienceId,
});
export const dispatchUpdateCanEditPermission = (
  audienceId: string,
  canedit: boolean
) => ({
  type: updateUserCanedit,
  audienceId,
  canedit,
});
export const dispatchDeleteAudienceUsersRequest = () => ({
  type: deleteAudienceUserRequest,
});
export const dispatchDeleteAudienceUserSuccess = () => ({
  type: deleteAudienceUserSuccess,
});

export const dispatchUpdateAudienceRequest = () => ({
  type: updateAudienceRequest,
});
export const dispatchUpdateAudienceSuccess = () => ({
  type: updateAudienceSuccess,
});

export const dispatchDeleteAudienceRequest = () => ({
  type: deleteAudienceRequest,
});
export const dispatchDeleteAudienceSuccess = () => ({
  type: deleteAudienceSuccess,
});

export const dispatchAudiencesDragEnd = (dragEndResult: any) => ({
  type: audiencesDragEndSuccess,
  dragEndResult,
});

export const dispatchFetchB2CUsersByDomainsRequest = () => ({
  type: fetchB2CUsersRequest,
});

export const dispatchFetchB2CUsersByDomainsSuccess = (
  usersResp: B2CUserWithPagination
) => ({
  type: fetchB2CUsersSuccess,
  usersResp,
});

export const dispatchSearchB2CUsersSuccess = (usersResp: any) => ({
  type: searchB2CUsersSuccess,
  usersResp,
});

export const dispatchfetchThumbnailsRequest = () => ({
  type: fetchThumbnailsRequest,
});
export const dispatchfetchThumbnailsSuccess = (thumbnails: any) => ({
  type: fetchThumbnailsSuccess,
  thumbnails,
});

export const dispatchCheckAudienceNameRequest = () => ({
  type: checkAudienceNameRequest,
});
export const dispatchCheckAudienceNameSuccess = (existisChek: any) => ({
  type: checkAudienceNameSuccess,
  existisChek,
});
export const dispatchImportAudienceRequest = () => ({
  type: importAudienceRequest,
});
export const dispatchImportAudienceSuccess = () => ({
  type: importAudienceSuccess,
});
export const dispatchResetAudienceCheck = () => ({
  type: resetAudienceCheck,
});
export const dispatchFailure = () => ({
  type: Failure,
});
export const dispatchCreateAudiencesFailure = (
  createAudienceError: string
) => ({
  type: createAudiencesFailure,
  createAudienceError,
});

export const dispatchResetADUsersSuccess = () => ({
  type: resetADUsersSuccess,
});
export const dispatchUpdateADUsersSuccess = (updateAudienceUsers: any) => ({
  type: updateADUsers,
  updateAudienceUsers
})
