import { combineReducers } from "redux";
import modalReducer from "../../modals/modal-reducer";
import audiencesReducer from "./audiences";
import authReducer from "./auth";
import configureReducer from "./configuration";
import reportReducer from "./report";
import themeReducer from "./theme";
import datawareReducer from "./dataware";
import peoplesReducer from "./peoples";
import privacyReducer from "./privacy";

const rootReducer = combineReducers({
  authReducer,
  audiencesReducer,
  configureReducer,
  reportReducer,
  modalReducer,
  themeReducer,
  datawareReducer,
  peoplesReducer,
  privacyReducer,
});
export default rootReducer;
