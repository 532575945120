import { Configuration } from "../../models/configuration";

const types = {
  FETCH_PB_CONFIGURATION: "FETCH_PB_CONFIGURATION",
  CREATE_PB_SERVICE_ACCOUNT: "CREATE_PB_SERVICE_ACCOUNT",
  EDIT_PB_SERVICE_ACCOUNT: "EDIT_PB_SERVICE_ACCOUNT",
  GET_PB_SERVICE_ACCOUNT_BY_ID: "GET_PB_SERVICE_ACCOUNT_BY_ID",
  EXPORT_AUDIENCE_DATA: "EXPORT_AUDIENCE_DATA",
};

export const fetchConfigurationsRequest = `${types.FETCH_PB_CONFIGURATION}_REQUEST`;
export const fetchConfigurationsSuccess = `${types.FETCH_PB_CONFIGURATION}_SUCCESS`;
export const fetchConfigurationsFailure = `${types.FETCH_PB_CONFIGURATION}_FAILURE`;

export const createPBSrviceAccountRequest = `${types.CREATE_PB_SERVICE_ACCOUNT}_REQUEST`;
export const createPBSrviceAccountSuccess = `${types.CREATE_PB_SERVICE_ACCOUNT}_SUCCESS`;
export const createPBSrviceAccountFailure = `${types.CREATE_PB_SERVICE_ACCOUNT}_FAILURE`;

export const editPBSrviceAccountRequest = `${types.EDIT_PB_SERVICE_ACCOUNT}_REQUEST`;
export const editPBSrviceAccountSuccess = `${types.EDIT_PB_SERVICE_ACCOUNT}_SUCCESS`;

export const getPBSrviceAccountRequestById = `${types.GET_PB_SERVICE_ACCOUNT_BY_ID}_REQUEST`;
export const getPBSrviceAccountRequestByIdSuccess = `${types.GET_PB_SERVICE_ACCOUNT_BY_ID}_SUCCESS`;

export const exportAudienceDataRequest = `${types.EXPORT_AUDIENCE_DATA}_REQUEST`;
export const exportAudienceDataSucess = `${types.EXPORT_AUDIENCE_DATA}_SUCCESS`;

export const clearPowerBIServiceAccountState =
  "clearPowerBIServiceAccountState";

export const dispatchFetchConfigurationsRequest = () => ({
  type: fetchConfigurationsRequest,
});
export const dispatchFetchConfigurationsSuccess = (
  configurations: Array<Configuration>
) => ({
  type: fetchConfigurationsSuccess,
  configurations,
});
export const dispatchFetchConfigurationsFailure = () => ({
  type: fetchConfigurationsFailure,
});

export const dispatchCreatePBSrviceAccountRequest = () => ({
  type: createPBSrviceAccountRequest,
});
export const dispatchCreatePBSrviceAccountSuccess = (
  configuration: Configuration
) => ({
  type: createPBSrviceAccountSuccess,
  configuration,
});

export const dispatchEditPBSrviceAccountRequest = () => ({
  type: editPBSrviceAccountRequest,
});
export const dispatchEditPBSrviceAccountSuccess = (
  configuration: Configuration
) => ({
  type: editPBSrviceAccountSuccess,
  configuration,
});

export const dispatchGetPBSrviceAccountRequestById = () => ({
  type: getPBSrviceAccountRequestById,
});
export const dispatchGetPBSrviceAccountSuccessById = (
  configuration: Configuration
) => ({
  type: getPBSrviceAccountRequestByIdSuccess,
  configuration,
});
export const dispatchClearPowerBIServiceAccountState = () => ({
  type: clearPowerBIServiceAccountState,
});

export const dispatchExportAudienceDataRequest = () => ({
  type: exportAudienceDataRequest,
});

export const dispatchExportAudienceDataSuccess = (data: any) => ({
  type: exportAudienceDataSucess,
  data,
});
