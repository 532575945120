export const ROUTES = {
  PORTAL: "/portal",
  LOGIN: "/login",
  SIGN_IN: "/sign-in",
  NOT_FOUND: "/node-found",
  AUDIENCES: "/audiences",
  AUDIENCES_DETAIL: "/audiences/:audienceId",
  OWNDATA: "/configure/owndata",
  SERVICEACCOUNT: "/configure/serviceaccount",
  MONITOR: "/monitor",
  VIEW_REPORT:
    "/audiences/report/view/:audienceId/:groupId/:reportId/:visualType",
  SETTINGS: "/configure/settings",
  CONFIGURATION: "/configure",
  POLICY: "/policy",
  START: "/",
  CONFIGURE: "/configure",
  TEAMS_CONTEXT: "/teams-context",
  DATAWARE: "/dataware",
  USERS_AND_GROUPS: "/people",
  INGESTION: "/ingestion",
  PAGE_NOT_FOUND: "/notfound",
  PRIVACY: "/privacy",
  PRIVACY_SEARCH: "/privacy/search/",
  WIKI: "/docs",
};
